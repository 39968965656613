p {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  max-width: 40ch;
}

button {
  color: #ccc;
  border-radius: 15px;
  border: none;
  outline: none;
  padding: 0.7rem;
  margin: 0.5rem;
  background-color: #262626;
  cursor: pointer;
  padding: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  font-size: 1.2rem;
}

.btn-endgame {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
