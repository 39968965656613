body {
  background-image: linear-gradient(59deg, #0093e9 0%, #80d0c7 100%);
  position: relative;
}

h1 {
  margin: 2rem;
  font-size: 3rem;
  text-align: center;
}

button {
  color: #ccc;
  border-radius: 15px;
  border: none;
  outline: none;
  padding: 0.7rem;
  margin: 0.5rem;
  background-color: #262626;
  cursor: pointer;
  padding: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  font-size: 1.2rem;
}

.btn-endgame {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

input {
  font-size: 2rem;
  outline: none;
  color: #282828;
  border-radius: 15px;
  padding: 10px;
  border: none;
  transition: all 0.3s ease;
  margin: 1rem;
}

input:focus {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem;
}

.button-container {
  text-align: center;
}
